<!-- components/BankDetails.vue -->

<template>
    <b-card title="Bank Account Details">
      <b-form-group label="Bank Account Number" label-for="bank-account-number">
        <b-form-input
          id="bank-account-number"
          v-model="bankDetails.bankAccountNumber"
          :state="validateField('bankAccountNumber')"
        ></b-form-input>
      </b-form-group>
  
      <b-form-group label="Bank Name" label-for="bank-name">
        <b-form-input
          id="bank-name"
          v-model="bankDetails.bankName"
          :state="validateField('bankName')"
        ></b-form-input>
      </b-form-group>
  
      <b-form-group label="IFSC Code" label-for="ifsc-code">
        <b-form-input
          id="ifsc-code"
          v-model="bankDetails.ifscCode"
          :state="validateField('ifscCode')"
        ></b-form-input>
      </b-form-group>
  
      <!-- File Uploads -->
      <b-form-group label="Bank Account Statement">
        <b-form-file
          v-model="bankDetails.bankStatement"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="Bank Confirmation Letter">
        <b-form-file
          v-model="bankDetails.bankConfirmationLetter"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
    </b-card>
  </template>
  
  <script>
  export default {
    name: 'BankDetails',
    props: {
      value: Object,
      isSubmitted: Boolean,
    },
    data() {
      return {
        bankDetails: this.value || {},
      };
    },
    methods: {
      validateField(fieldName) {
        if (!this.isSubmitted) return null;
        return this.bankDetails[fieldName] ? true : false;
      },
    },
    watch: {
      bankDetails: {
        handler(newValue) {
          this.$emit('input', newValue);
        },
        deep: true,
      },
    },
  };
  </script>
  