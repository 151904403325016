<!-- components/TaxDetails.vue -->

<template>
    <b-card title="Tax Details">
      <b-form-group label="Tax Identification Number (TIN)" label-for="tin">
        <b-form-input
          id="tin"
          v-model="taxDetails.taxIdentificationNumber"
          :state="validateField('taxIdentificationNumber')"
        ></b-form-input>
        <b-form-invalid-feedback>TIN is required.</b-form-invalid-feedback>
      </b-form-group>
  
      <b-form-group label="GST Registration Number" label-for="gst">
        <b-form-input
          id="gst"
          v-model="taxDetails.gstRegistrationNumber"
          :state="validateField('gstRegistrationNumber')"
        ></b-form-input>
      </b-form-group>
  
      <b-form-group label="PAN Number" label-for="pan">
        <b-form-input
          id="pan"
          v-model="taxDetails.panNumber"
          :state="validateField('panNumber')"
        ></b-form-input>
      </b-form-group>
  
      <!-- File Uploads -->
      <b-form-group label="TIN or VAT Registration Certificate">
        <b-form-file
          v-model="taxDetails.tinCertificate"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="GST Registration Certificate">
        <b-form-file
          v-model="taxDetails.gstCertificate"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="PAN Card">
        <b-form-file
          v-model="taxDetails.panCard"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
        ></b-form-file>
      </b-form-group>
    </b-card>
  </template>
  
  <script>
  export default {
    name: 'TaxDetails',
    props: {
      value: Object,
      isSubmitted: Boolean,
    },
    data() {
      return {
        taxDetails: this.value || {},
      };
    },
    methods: {
      validateField(fieldName) {
        if (!this.isSubmitted) return null;
        return this.taxDetails[fieldName] ? true : false;
      },
    },
    watch: {
      taxDetails: {
        handler(newValue) {
          this.$emit('input', newValue);
        },
        deep: true,
      },
    },
  };
  </script>
  