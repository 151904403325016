<!-- components/VendorRegistration.vue -->

<template>
  <b-container>
    <b-form @submit.prevent="submitForm">
      <!-- Vendor Details -->
      <vendor-details
        v-model="vendorDetails"
        :is-submitted="isSubmitted"
      ></vendor-details>

      <!-- Authorized Signatory -->
      <authorized-signatory
        v-model="authorizedSignatory"
        :is-submitted="isSubmitted"
      ></authorized-signatory>

      <!-- Tax Details -->
      <tax-details
        v-model="taxDetails"
        :is-submitted="isSubmitted"
      ></tax-details>

      <!-- Bank Details -->
      <bank-details
        v-model="bankDetails"
        :is-submitted="isSubmitted"
      ></bank-details>

      <!-- Financial Documents -->
      <financial-documents
        v-model="financialDocuments"
        :is-submitted="isSubmitted"
      ></financial-documents>

      <!-- Experience and References -->
      <experience-references
        v-model="experienceReferences"
        :is-submitted="isSubmitted"
      ></experience-references>

      <!-- Compliance Documents -->
      <compliance-documents
        v-model="complianceDocuments"
        :is-submitted="isSubmitted"
      ></compliance-documents>

      <!-- Action Buttons -->
      <b-button variant="secondary" @click="saveDraft">Save as Draft</b-button>
      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </b-container>
</template>

<script>
// import VendorDetails from './VendorDetails.vue'
import AuthorizedSignatory from './AuthorizedSignatory.vue';
import TaxDetails from './TaxDetails.vue';
import BankDetails from './BankDetails.vue';
import FinancialDocuments from './FinancialDocuments.vue';
import ExperienceReferences from './ExperienceReferences.vue';
import ComplianceDocuments from './ComplianceDocuments.vue';

import vendorService from '@/services/vendor-service';

export default {
  name: 'VendorRegistration',
  components: {
    VendorDetails,
    AuthorizedSignatory,
    TaxDetails,
    BankDetails,
    FinancialDocuments,
    ExperienceReferences,
    ComplianceDocuments,
  },
  data() {
    return {
      vendorId: null,
      isSubmitted: false,
      // Data objects for each section
      vendorDetails: {
        vendorName: '',
        registeredAddress: '',
        certificateOfIncorporation: null,
        memorandumArticles: null,
        partnershipDeed: null,
        tradeLicense: null,
        utilityBill: null,
        leaseAgreement: null,
        propertyTaxReceipt: null,
        municipalLicense: null,
      },
      authorizedSignatory: {
        authorizedPersonName: '',
        contactNumber: '',
        emailID: '',
        passport: null,
        nationalIdentityCard: null,
        driversLicense: null,
        aadhaarCard: null,
      },
      taxDetails: {
        taxIdentificationNumber: '',
        gstRegistrationNumber: '',
        panNumber: '',
        tinCertificate: null,
        gstCertificate: null,
        panCard: null,
      },
      bankDetails: {
        bankAccountNumber: '',
        bankName: '',
        ifscCode: '',
        bankStatement: null,
        bankConfirmationLetter: null,
      },
      financialDocuments: {
        auditedFinancialStatements: null,
        bankReferenceLetter: null,
        incomeTaxReturns: null,
      },
      experienceReferences: {
        previousClientsProjects: '',
        referenceLetters: null,
      },
      complianceDocuments: {
        amlPolicy: null,
        antiBriberyPolicy: null,
        codeOfConduct: null,
      },
    };
  },
  methods: {
    async submitForm() {
      try {
        this.isSubmitted = true;
        const formData = this.createFormData('submitted');
        const response = await vendorService.saveVendor(formData);
        alert('Vendor submitted successfully!');
        // Redirect or perform other actions as needed
      } catch (error) {
        console.error(error);
        alert('An error occurred during submission.');
      }
    },
    async saveDraft() {
      try {
        this.isSubmitted = false;
        const formData = this.createFormData('draft');
        const response = await vendorService.saveVendor(formData);
        this.vendorId = response.data.vendorId;
        alert('Draft saved successfully!');
      } catch (error) {
        console.error(error);
        alert('An error occurred while saving draft.');
      }
    },
    createFormData(status) {
      const formData = new FormData();
      formData.append('status', status);
      if (this.vendorId) {
        formData.append('vendorId', this.vendorId);
      }

      // Append data from components
      this.appendData(formData, this.vendorDetails);
      this.appendData(formData, this.authorizedSignatory);
      this.appendData(formData, this.taxDetails);
      this.appendData(formData, this.bankDetails);
      this.appendData(formData, this.financialDocuments);
      this.appendData(formData, this.experienceReferences);
      this.appendData(formData, this.complianceDocuments);

      return formData;
    },
    appendData(formData, data) {
      for (let key in data) {
        if (data[key] instanceof File || data[key] instanceof Blob) {
          formData.append(key, data[key]);
        } else if (Array.isArray(data[key])) {
          // Handle arrays (if any)
          data[key].forEach((item) => {
            formData.append(key, item);
          });
        } else if (data[key] !== undefined && data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
    },
    async loadVendorData() {
      if (this.vendorId) {
        try {
          const response = await vendorService.getVendorById(this.vendorId);
          const vendorData = response.data;

          // Populate vendorDetails
          this.vendorDetails.vendorName = vendorData.vendorName || '';
          this.vendorDetails.registeredAddress = vendorData.registeredAddress || '';

          // For documents, you might need to handle URLs or file placeholders
          // Example:
          // this.vendorDetails.certificateOfIncorporation = vendorData.documents.find(doc => doc.documentType === 'certificateOfIncorporation') || null;

          // Populate authorizedSignatory
          this.authorizedSignatory.authorizedPersonName = vendorData.authorizedPersonName || '';
          this.authorizedSignatory.contactNumber = vendorData.contactNumber || '';
          this.authorizedSignatory.emailID = vendorData.emailID || '';

          // Populate taxDetails
          this.taxDetails.taxIdentificationNumber = vendorData.taxIdentificationNumber || '';
          this.taxDetails.gstRegistrationNumber = vendorData.gstRegistrationNumber || '';
          this.taxDetails.panNumber = vendorData.panNumber || '';

          // Similarly, populate other data objects
          // bankDetails, financialDocuments, experienceReferences, complianceDocuments

          // Note: For documents, you may need to handle displaying existing files
          // and allow users to replace them. This typically involves setting up
          // file URLs or managing file inputs differently when editing.

        } catch (error) {
          console.error(error);
          alert('An error occurred while loading vendor data.');
        }
      }
    },
  },
  created() {
    this.vendorId = this.$route.params.vendorId || null;
    if (this.vendorId) {
      this.loadVendorData();
    }
  },
};
</script>
