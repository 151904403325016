// services/vendorService.js

import axios from 'axios';

const API_URL = '/api/vendors/';

export default {
  saveVendor(formData) {
    return axios.post(`${API_URL}save`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getVendorById(vendorId) {
    return axios.get(`${API_URL}${vendorId}`);
  },
};
