<!-- components/ComplianceDocuments.vue -->

<template>
    <b-card title="Compliance Documents">
      <!-- File Uploads -->
      <b-form-group label="Anti-Money Laundering (AML) Policy">
        <b-form-file
          v-model="complianceDocuments.amlPolicy"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="Anti-Bribery and Corruption Policy">
        <b-form-file
          v-model="complianceDocuments.antiBriberyPolicy"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="Code of Conduct or Ethics Policy">
        <b-form-file
          v-model="complianceDocuments.codeOfConduct"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
    </b-card>
  </template>
  
  <script>
  export default {
    name: 'ComplianceDocuments',
    props: {
      value: Object,
      isSubmitted: Boolean,
    },
    data() {
      return {
        complianceDocuments: this.value || {},
      };
    },
    watch: {
      complianceDocuments: {
        handler(newValue) {
          this.$emit('input', newValue);
        },
        deep: true,
      },
    },
  };
  </script>
  