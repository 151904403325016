<!-- components/AuthorizedSignatory.vue -->

<template>
    <b-card title="Authorized Signatory">
      <b-form-group label="Authorized Person Name" label-for="authorized-person-name">
        <b-form-input
          id="authorized-person-name"
          v-model="authorizedSignatory.authorizedPersonName"
          :state="validateField('authorizedPersonName')"
          required
        ></b-form-input>
        <b-form-invalid-feedback>Name is required.</b-form-invalid-feedback>
      </b-form-group>
  
      <b-form-group label="Contact Number" label-for="contact-number">
        <b-form-input
          id="contact-number"
          v-model="authorizedSignatory.contactNumber"
          :state="validateField('contactNumber')"
          required
        ></b-form-input>
        <b-form-invalid-feedback>Contact Number is required.</b-form-invalid-feedback>
      </b-form-group>
  
      <b-form-group label="Email ID" label-for="email-id">
        <b-form-input
          id="email-id"
          type="email"
          v-model="authorizedSignatory.emailID"
          :state="validateField('emailID')"
          required
        ></b-form-input>
        <b-form-invalid-feedback>Email ID is required.</b-form-invalid-feedback>
      </b-form-group>
  
      <!-- Identity Proof Upload -->
      <b-form-group label="Identity Proof">
        <b-form-file
          v-model="authorizedSignatory.identityProof"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
        ></b-form-file>
      </b-form-group>
    </b-card>
  </template>
  
  <script>
  export default {
    name: 'AuthorizedSignatory',
    props: {
      value: Object,
      isSubmitted: Boolean,
    },
    data() {
      return {
        authorizedSignatory: this.value || {},
      };
    },
    methods: {
      validateField(fieldName) {
        if (!this.isSubmitted) return null;
        return this.authorizedSignatory[fieldName] ? true : false;
      },
    },
    watch: {
      authorizedSignatory: {
        handler(newValue) {
          this.$emit('input', newValue);
        },
        deep: true,
      },
    },
  };
  </script>
  