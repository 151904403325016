<!-- components/FinancialDocuments.vue -->

<template>
    <b-card title="Financial Documents">
      <!-- File Uploads -->
      <b-form-group label="Audited Financial Statements">
        <b-form-file
          v-model="financialDocuments.auditedFinancialStatements"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="Bank Reference Letter">
        <b-form-file
          v-model="financialDocuments.bankReferenceLetter"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
  
      <b-form-group label="Income Tax Returns">
        <b-form-file
          v-model="financialDocuments.incomeTaxReturns"
          accept=".pdf,.doc,.docx"
        ></b-form-file>
      </b-form-group>
    </b-card>
  </template>
  
  <script>
  export default {
    name: 'FinancialDocuments',
    props: {
      value: Object,
      isSubmitted: Boolean,
    },
    data() {
      return {
        financialDocuments: this.value || {},
      };
    },
    watch: {
      financialDocuments: {
        handler(newValue) {
          this.$emit('input', newValue);
        },
        deep: true,
      },
    },
  };
  </script>
  